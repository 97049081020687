import React from "react";
import Slider from "react-slick";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebook, faInstagram, faLinkedin, faGithub } from "@fortawesome/free-brands-svg-icons";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import fn from "../assets/fn.png";
import mem1 from "../assets/mem1.jpg";
import mem2 from "../assets/mem2.jpg";
import mem3 from "../assets/mem3.png";

const Team = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    pauseOnHover: true,
    arrows: false,
  };

  return (
    <div
      id="team"
      className="team rounded-lg m-1 mt-2 mb-2 bg-gray-100 py-16 px-8 w-full"
      style={{ backgroundImage: `url(${fn})` }}
    >
      <div className="team__header text-center mb-12 w-full">
        <h2 className="text-4xl font-bold text-black text-hand-drawn">Founding Members</h2>
        <p className="text-lg text-gray-700 mt-4 text-hand-drawn">Meet our founding members</p>
      </div>

      <Slider {...settings}>
        {/* Team Member 1 */}
        <div
          className="team__member text-center p-6 shadow-lg rounded-lg hover:scale-105 transition-all duration-300 border border-white"
          style={{ backgroundImage: `url(${fn})` }}
        >
          <div className="w-32 h-32 mx-auto flex items-center justify-center rounded-full ring-4 ring-transparent group-hover:ring-8 group-hover:ring-blue-500 border border-blue-100">
            <img
              src={mem1}
              alt="Uday"
              className="w-full h-full object-cover rounded-full group-hover:scale-90 transition-all duration-300"
              loading="lazy"
              style={{ width: "128px", height: "128px" }} // Explicit width and height
            />
          </div>
          <h3 className="text-xl font-semibold text-black text-hand-drawn mt-6">John Doe</h3>
          <p className="text-gray-500 text-hand-drawn">UDAY - CEO</p>
          <p className="text-sm text-gray-600 mt-3 text-hand-drawn">
            Leading the team with a vision for innovation.
          </p>
          <div className="social-links mt-4 flex justify-center space-x-4">
            <a href="https://www.linkedin.com/in/uday-v-37515b253" target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon icon={faLinkedin} size="lg" className="text-gray-600 hover:text-blue-600 transition-colors duration-300" />
            </a>
            <a href="https://github.com" target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon icon={faGithub} size="lg" className="text-gray-600 hover:text-gray-900 transition-colors duration-300" />
            </a>
            <a href="https://instagram.com" target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon icon={faInstagram} size="lg" className="text-gray-600 hover:text-pink-500 transition-colors duration-300" />
            </a>
            <a href="https://facebook.com" target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon icon={faFacebook} size="lg" className="text-gray-600 hover:text-blue-700 transition-colors duration-300" />
            </a>
          </div>
        </div>

        {/* Team Member 2 */}
        <div
          style={{ backgroundImage: `url(${fn})` }}
          className="border team__member text-center p-6 shadow-lg rounded-lg hover:scale-105 transition-all duration-300"
        >
          <div className="w-32 h-32 mx-auto flex items-center justify-center rounded-full ring-4 ring-transparent group-hover:ring-8 group-hover:ring-blue-500">
            <img
              src={mem2}
              alt="Jane Doe"
              className="w-full h-full object-cover rounded-full group-hover:scale-90 transition-all duration-300"
              loading="lazy"
              style={{ width: "128px", height: "128px" }} // Explicit width and height
            />
          </div>
          <h3 className="text-xl font-semibold text-black text-hand-drawn mt-6">Jane Doe</h3>
          <p className="text-gray-500 text-hand-drawn">HARISHCHANDRA MARATHI - CFO</p>
          <p className="text-sm text-gray-600 mt-3 text-hand-drawn">
            Overseeing Technical Strategies And Implementations And Sees Over Finance Related Aspect in the Company.
          </p>
          <div className="social-links mt-4 flex justify-center space-x-4">
            <a href="https://www.linkedin.com/in/hari-marathi/" target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon icon={faLinkedin} size="lg" className="text-gray-600 hover:text-blue-600 transition-colors duration-300" />
            </a>
            <a href="https://github.com/hari-dev-2004/" target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon icon={faGithub} size="lg" className="text-gray-600 hover:text-gray-900 transition-colors duration-300" />
            </a>
            <a href="https://www.instagram.com/hari_marathi/" target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon icon={faInstagram} size="lg" className="text-gray-600 hover:text-pink-500 transition-colors duration-300" />
            </a>
            <a href="https://www.facebook.com/profile.php?id=100035165478705&name=xhp_nt_fbactionopen_user&_n=K" target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon icon={faFacebook} size="lg" className="text-gray-600 hover:text-blue-700 transition-colors duration-300" />
            </a>
          </div>
        </div>

        {/* Team Member 3 */}
        <div
          style={{ backgroundImage: `url(${fn})` }}
          className="border team__member text-center p-6 shadow-lg rounded-lg hover:scale-105 transition-all duration-300"
        >
          <div className="w-32 h-32 mx-auto flex items-center justify-center rounded-full ring-4 ring-transparent group-hover:ring-8 group-hover:ring-blue-500">
            <img
              src={mem3}
              alt="Sam Smith"
              className="w-full h-full object-cover rounded-full group-hover:scale-90 transition-all duration-300"
              loading="lazy"
              style={{ width: "128px", height: "128px" }} // Explicit width and height
            />
          </div>
          <h3 className="text-xl font-semibold text-black text-hand-drawn mt-6">OM BAIKERIKAR -CTO</h3>
          <p className="text-gray-500 text-hand-drawn">OM BAIKERIKAR - CTO</p>
          <p className="text-sm text-gray-600 mt-3 text-hand-drawn">
            Building the core functionality and tech stack Handles The Hardware and Manages the Project requirement .
          </p>
          <div className="social-links mt-4 flex justify-center space-x-4">
            <a href="https://linkedin.com" target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon icon={faLinkedin} size="lg" className="text-gray-600 hover:text-blue-600 transition-colors duration-300" />
            </a>
            <a href="https://github.com" target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon icon={faGithub} size="lg" className="text-gray-600 hover:text-gray-900 transition-colors duration-300" />
            </a>
            <a href="https://instagram.com" target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon icon={faInstagram} size="lg" className="text-gray-600 hover:text-pink-500 transition-colors duration-300" />
            </a>
            <a href="https://facebook.com" target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon icon={faFacebook} size="lg" className="text-gray-600 hover:text-blue-700 transition-colors duration-300" />
            </a>
          </div>
        </div>
      </Slider>
    </div>
  );
};

export default Team;
