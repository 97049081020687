import React, { useState, useEffect } from "react";
import img1 from '../assets/img1.png';
import img2 from '../assets/img2.png';
import img3 from '../assets/img3.png';
import img4 from '../assets/img4.png';
import img5 from '../assets/img5.png';
import img6 from '../assets/img6.png';
import img7 from '../assets/img7.png';
import img8 from '../assets/hero.png';
import './hero.css'
const imagelist = [img1, img2, img3, img4, img5, img6, img7,img8];

const Hero = () => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % imagelist.length);
    }, 3000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div
      className="hero w-full h-[600px] bg-transparent rounded-lg overflow-hidden relative m-1" id="hero"
      style={{
        backgroundImage: `url(${imagelist[currentImageIndex]})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
      }}
    >
      <div className="w-80 bg-transparent rounded-lg p-4 border-4 bg-opacity-80  align-top rtl:">
        <h1 className="text-2xl text-hand-drawn font-bold hand-drawn-border">
          Introducing Fusionneat: Revolutionizing Fintech with AI-powered Solutions
        </h1>
        <h2 className="text-xl mt-4 text-hand-drawn hand-drawn-border text-white">
          Stay ahead of the curve with our innovative financial advisory and lending/borrowing platforms
        </h2>
        <button className="border-s-indigo-500 mt-6 px-4 py-2 bg-white hover:bg-white text-black font-bold rounded-full shadow-md hand-drawn-btn">
          Stay Updated on Our Launch
        </button>
      </div>
    </div>
  );
};

export default Hero;
