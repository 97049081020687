import React, { useEffect } from "react";
import './update.css';

const Updates = () => {
  const updatesData = [
    {
      title: "Vastorix",
      description: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. At quas eaque autem quia unde laboriosam, nostrum rerum inventore, enim nisi omnis debitis, quis aliquid explicabo architecto amet. Dolores, possimus esse?",
      link: "https://www.vastorix.com", // Replace with actual website
    },
    {
      title: "Visual Structs",
      description: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. At quas eaque autem quia unde laboriosam, nostrum rerum inventore, enim nisi omnis debitis quis aliquid explicabo architecto amet. Dolores, possimus esse?",
      link: "https://www.visualstructs.com", // Replace with actual website
    },
    {
      title: "Credx",
      description: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. At quas eaque autem quia unde laboriosam, nostrum rerum inventore, enim nisi omnis debitis quis aliquid explicabo architecto amet. Dolores, possimus esse?",
      link: "https://www.credx.com", // Replace with actual website
    },
  ];

  useEffect(() => {
    const cards = document.querySelectorAll('.card');
    cards.forEach((card, index) => {
      card.style.animationDelay = `${index * 0.3}s`; // Apply delay to each card
    });
  }, []);

  return (
    <div className="updates-container">
      <h2 className="updates-title text-hand-drawn">Stay Updated on Our Launch</h2>
      <div className="updates-grid">
        {updatesData.map((update, index) => (
          <div
            key={index}
            className="card"
            onClick={() => window.open(update.link, "_blank")}
          >
            <h1 className="card-title text-hand-drawn">{update.title}</h1>
            <p className="card-description text-hand-drawn">{update.description}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Updates;
