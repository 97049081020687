import React from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import Navbar from './component/nav.jsx';
import Contact from './component/contact.jsx';


function App() {
  const location = useLocation();

  // Exclude Navbar for the /contact route
  const standalonePages = ['/contact'];
  const isStandalone = standalonePages.includes(location.pathname);

  return (
    <>
      {!isStandalone && <Navbar />} {/* Navbar renders only for non-standalone routes */}
      <Routes>

<Route path="/contact" element={<Contact />} />


</Routes>
    </>
  );
}

export default App;
