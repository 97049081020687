// Preloader.jsx
import React, { useEffect, useState } from "react";
import "./preloader.css";
import fu from '../assets/Fu.gif';
const Preloader = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
      
    }, 3000); 

    return () => clearTimeout(timer);
  }, []);

  if (!loading) {
    return null;
  }

  return (
    <div className="preloader">
        <img src={fu} alt="Fusionneat" />

    </div>
  );
};

export default Preloader;