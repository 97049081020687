import React from "react";
import "./Product.css";

const Product = () => {
    return (
        <>
       <div className="product bg-slate-400 border border-dashed m-2 p-4 rounded-md shadow-md hover:shadow-lg transition duration-300 ease-in-out transform hover:scale-105">


        <h1 className="text-center font-serif ">our products</h1>
            <div className="product-image">
                <img src="https://via.placeholder.com/150" alt="product" />
            </div>
            
            <div className="product-details">
                <h3>Product Name</h3>
                <p>Product Description</p>
                <p>Price: $10.00</p>
            </div>
        </div>
        </>
    );
};
export default Product;