import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "./Contact.css";

const Contact = () => {
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");


    

    // Cleanup the interval when the component unmounts
 

  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    const name = formData.get("name");
    const email = formData.get("email");
    const message = formData.get("message");

    if (name && email && message) {
      setSuccess("Thank you for contacting us! We'll get back to you soon.");
      setError("");
    } else {
      setError("Please fill out all fields.");
      setSuccess("");
    }
  };

  return (
    <div className="flex items-center justify-center min-h-screen grid-container" id="bodys">
      {/* Grid Overlay */}
      <div className="grid-overlay"></div>

      {/* Contact Form */}
      <div className="form-container w-full max-w-lg bg-gray-800 bg-opacity-70 p-8 rounded-lg shadow-lg">
        <h2 className="text-3xl font-semibold text-center mb-6 text-gray-300">Get in Touch</h2>
        <form onSubmit={handleSubmit} className="space-y-4">
          <div>
            <label htmlFor="name" className="block text-sm font-medium text-gray-300">Name:</label>
            <input
              type="text"
              id="name"
              name="name"
              className="mt-1 block w-full rounded-md border-gray-500 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
              required
            />
          </div>

          <div>
            <label htmlFor="email" className="block text-sm font-medium text-gray-300">Email:</label>
            <input
              type="email"
              id="email"
              name="email"
              className="mt-1 block w-full rounded-md border-gray-500 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
              required
            />
          </div>

          <div>
            <label htmlFor="message" className="block text-sm font-medium text-gray-300">Message:</label>
            <textarea
              id="message"
              name="message"
              rows="4"
              className="mt-1 block w-full rounded-md border-gray-500 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
              required
            ></textarea>
          </div>

          <button
            type="submit"
            className="w-full bg-indigo-600 text-white py-2 px-4 rounded-md hover:bg-indigo-700 transition duration-300"
          >
            Submit
          </button>

          {error && <p className="text-red-600 text-sm mt-2">{error}</p>}
          {success && <p className="text-green-600 text-sm mt-2">{success}</p>}
        </form>

        {/* Back to Home */}
        <div className="mt-6 text-center">
          <Link to="/" className="inline-block bg-gray-200 text-gray-700 px-4 py-2 rounded-md hover:bg-gray-300 transition duration-300">
            Back to Home
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Contact;
