import React from "react";
import "./project.css";

const Project = () => {
  const projects = [
    {
      name: "Visionary Project",
      description:
        "A smart navigation system specifically designed for visually impaired individuals. It detects obstacles, classifies them as movable or non-movable, and guides the user to navigate safely around them. Key features include advanced road safety monitoring, object detection, pathfinding algorithms, and OCR capabilities to identify and read text. The system ensures safer and more independent movement for users in complex environments.",
      github: "https://github.com/uday68/visionary-assistant.git", // Replace with your actual GitHub link
    },
    {
      name: "Learning Efficient Tool",
      description:
        "An AI-powered platform that personalizes learning paths based on user strengths and weaknesses. It leverages machine learning algorithms to analyze user performance, recommend optimized study strategies, and provide detailed progress reports. Ideal for students and professionals, this tool enhances productivity by focusing on the most effective learning techniques tailored to individual needs.",
      github: "https://github.com/yourusername/learning-efficient-tool", // Replace with your actual GitHub link
    },
    {
      name: "Graph and Algorithm Visualizer",
      description: `An interactive web-based application to visualize data structures and algorithms. 
      - **Custom Graph Creation**: Users can dynamically build graphs by adding nodes, edges, and weights.
      - **Algorithm Visualization**: Apply algorithms like Dijkstra's shortest path, DFS, BFS, Kruskal's MST, and more to see step-by-step execution visually.
      - **Debugging Programs**: Accepts user-written programs, animates execution flow, and identifies logical errors.
      - **Execution Flow**: Animations depict algorithm traversal, visited nodes, edge weights, and results in real time.
      - **Exportable Outputs**: Users can download reports as PDFs or export animations as GIFs for further use.
      - **Responsive Design**: Accessible for both mobile and desktop users, ensuring a seamless experience for learners, educators, and developers.`,
      github: "https://github.com/uday68/visual_struct.git", // Replace with your actual GitHub link
    },
    {
      name: "Smart Health Monitor",
      description:
        "A health monitoring application that integrates IoT devices to track real-time health metrics such as heart rate, blood pressure, and oxygen saturation. The platform provides detailed analytics, anomaly detection, and health trend reports, ensuring users can monitor their health and receive timely alerts for potential health issues.",
      github: "https://github.com/yourusername/smart-health-monitor", // Replace with your actual GitHub link
    },
  ];

  return (
    <div className="project" id="products">
      <h2>Our Team Projects</h2>
      <div className="projects-list">
        {projects.map((project, index) => (
          <div key={index} className="project-card">
            <h3>{project.name}</h3>
            <p>{project.description}</p>
            <a
              href={project.github}
              target="_blank"
              rel="noopener noreferrer"
              className="github-link"
            >
              View on GitHub
            </a>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Project;
