import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter, Routes, Route } from 'react-router-dom'; // Add Routes and Route for routing
import './component/index.css';
import 'bootstrap/dist/css/bootstrap.min.css';

import App from './App.jsx';
import Team from './component/team.jsx';
import Footer from './component/footer.jsx';
import Preloader from './component/animation.jsx';
import Project from './component/project.jsx';
import Hero from './component/hero.jsx';
import Updates from './component/updates.jsx';
import Product from './component/Product.jsx';
import Contact from './component/contact.jsx';
const rootElement = document.getElementById('root');
const root = createRoot(rootElement);

root.render(
  <StrictMode>
    <Preloader />
    <BrowserRouter>
      <App />
      <Routes>
        <Route path="/" element={<Hero />} />
        <Route path="/team" element={<Team />} />
        <Route path="/project" element={<Project />} />
        <Route path="/product" element={<Product />} />
        <Route path="/updates" element={<Updates />} />
        <Route path="/contact" element={<Contact />} />
      </Routes>
      <Team />
      <Project />
      <Product />
      <Updates />
      
      <Footer />
    </BrowserRouter>
  </StrictMode>
);
