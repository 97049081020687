import React from "react";
import { Container, Row, Col } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebook, faInstagram, faLinkedin, faGithub } from "@fortawesome/free-brands-svg-icons";
import { faEnvelope, faPhone } from "@fortawesome/free-solid-svg-icons";
import "./Footer.css";
import "./hero.css"
const Footer = () => {
  return (
    <>
    <footer className="footer bg-dark text-light py-4">
      <Container>
        <Row>
          <Col className="text-center">
            <h5 className="footer-heading mb-3 text-hand-drawn">Contact Us</h5>
            <ul className="footer-links list-unstyled">
              <li>
                <a href="https://www.linkedin.com" target="_blank" rel="noopener noreferrer">
                  <FontAwesomeIcon icon={faLinkedin} className="footer-icon text-hand-drawn" /> LinkedIn
                </a>
              </li>
              <li>
                <a href="https://github.com" target="_blank" rel="noopener noreferrer">
                  <FontAwesomeIcon icon={faGithub} className="footer-icon text-hand-drawn" /> GitHub
                </a>
              </li>
              <li>
                <a href="https://www.instagram.com" target="_blank" rel="noopener noreferrer">
                  <FontAwesomeIcon icon={faInstagram} className="footer-icon text-hand-drawn" /> Instagram
                </a>
              </li>
              <li>
                <a href="https://www.facebook.com" target="_blank" rel="noopener noreferrer">
                  <FontAwesomeIcon icon={faFacebook} className="footer-icon text-hand-drawn" /> Facebook
                </a>
              </li>
              <li>
                <a href="mailto:contact@example.com">
                  <FontAwesomeIcon icon={faEnvelope} className="footer-icon text-hand-drawn" /> contact@example.com
                </a>
              </li>
              <li>
                <a href="tel:+1234567890">
                  <FontAwesomeIcon icon={faPhone} className="footer-icon" /> +1 234 567 890
                </a>
              </li>
            </ul>
          </Col>
        </Row>
        <Row className="mt-4">
          <Col className="text-center">
            <p className="mb-0 footer-copy text-hand-drawn">&copy; {new Date().getFullYear()} Fusionneat. All Rights Reserved.</p>
          </Col>
        </Row>
      </Container>
    </footer>
    </>
  );
};

export default Footer;
